<template>
   <div class="content-wrapper">
      <section class="content">
         <div id="my-html-content">

            <div v-if="reports === null" class="d-flex justify-content-center">{{ $t('loading') }}</div>
            <div v-else>
               <h4 class="my-3" style="display: flex; flex-direction: column; align-items: center">
                  Relatório Mensal das Novas Assinaturas - {{ $route.params.year }}
               </h4>

               <table class="table table-bordered">
                  <thead>
                  <tr>
                     <th>Mês</th>
                     <th>Parceiro</th>
                     <th>Cupom</th>
                     <th>Desconto</th>
                     <th>Quantidade de Uso</th>
                     <th>Valor Descontado</th>
                     <th>Valor Arrecadado</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(report, index) in treatedReports" :key="index">
                     <td v-if="report.isFirstMonth" :rowspan="report.quantityMonths">{{ report.monthName }}</td>
                     <td>{{ report.partnerName }}</td>
                     <td>{{ report.couponName }}</td>
                     <td>{{ report.discountPercent }}</td>
                     <td>{{ report.usageQuantity }}</td>
                     <td>R$ {{ report.discountedAmount }}</td>
                     <td>R$ {{ report.collectedAmount }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                     <td colspan="7"></td>
                  </tr>
                  <tr>
                     <td colspan="5"><b>TOTAL</b></td>
                     <td><b>R$ {{ discountedTotal }}</b></td>
                     <td><b>R$ {{ collectedTotal }}</b></td>
                  </tr>
                  </tfoot>
               </table>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import html2pdf from "html2pdf.js/src";
import reportService from "../services/report-service";

export default {
   name: "ReportMonthly",
   data() {
      return {
         reports: null,
         discountedTotal: 0,
         collectedTotal: 0
      };
   },
   computed: {
      treatedReports () {
         let treatedReports = this.reports.sort((a, b) => a.month - b.month);

         const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
         //include (isFirstMonth = true) in first months for format correctly the table above.
         months.forEach((month) => {
            let isFirstMonth = true;

            treatedReports.map((report) => {
               if (report.month == month) {
                  report.isFirstMonth = isFirstMonth;
                  isFirstMonth = false;
               }
            });
         });

         const countMonths= {};
         //the countMonths was used in table rowspan
         treatedReports.forEach((report) => {

            const month = report.month;
            if (countMonths[month]) {
               countMonths[month]++;
            } else {
               countMonths[month] = 1;
            }
         });
         treatedReports.forEach((report) => {
            report.quantityMonths = countMonths[report.month];
            this.discountedTotal += report.discountedAmount;
            this.collectedTotal += report.collectedAmount;
         });

         treatedReports = this.formatCurrency(treatedReports);

         return treatedReports;
      }
   },
   async created() {

      this.reports = await reportService.reportMonthly(this.$route.params.year);

      setTimeout(() => {
         const element = document.getElementById('my-html-content');
         const opt = {
            margin:       0.5,
            filename:     'relatorio.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 5 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'landscape' }
         };
         const pdf = html2pdf().from(element).set(opt);
         pdf.save(); // save the PDF

         window.print();
      }, 1000)
   },
   methods: {
      formatCurrency (reports) {
         reports.map((result) => {
            result.collectedAmount = result.collectedAmount.toLocaleString('pt-br', {minimumFractionDigits: 2});
            result.discountedAmount = result.discountedAmount.toLocaleString('pt-br', {minimumFractionDigits: 2});
         });

         this.discountedTotal = this.discountedTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});
         this.collectedTotal = this.collectedTotal.toLocaleString('pt-br', {minimumFractionDigits: 2});

         return reports;
      }
   }
}
</script>

<style scoped>

</style>
